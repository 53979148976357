html {
  width: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

input,
textarea,
select,
button {
  font-family: inherit;
}
